import React, { useRef, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import {
    Avatar,
    Box,
    ButtonBase,
    Hidden,
    Menu,
    MenuItem,
    Typography,
    makeStyles,
    Modal,
    Switch,
} from '@material-ui/core'
//import { createMuiTheme } from 'src/theme'
import useAuth from 'src/hooks/useAuth'
import useSettings from 'src/hooks/useSettings'
import SwitchOrganization from './SwitchOrganization'
import useUser from 'src/hooks/useUser'
import { canAccessAcount, canSwitchOrganization } from 'src/utils/permission'

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 32,
        width: 32,
        marginRight: theme.spacing(1),
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.purple,
        fontSize: '15px',
        fontWeight: '600'
    },
    popover: {
        width: 200,
    },
    switchOrgModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    switchOrg: {
        width: '30em',
    },
}))

const Account = () => {
    const classes = useStyles()
    const { settings, saveSettings } = useSettings()
    const history = useHistory()
    const ref = useRef(null)
    const { user, logout } = useAuth()
    const [isOpen, setOpen] = useState(false)
    const [showSwitchOrganization, setShowSwitchOrganization] = useState(false)
    const [theme, setTheme] = useState(
        JSON.parse(localStorage.getItem('settings'))?.theme === "S2" ? 'Light' : 'Dark'
    )
    const userDetails = useUser()
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleLogout = async () => {
        try {
            handleClose()
            await logout()
            // localStorage.clear()
            history.push('/')
        } catch (err) {}
    }

    // const muiTheme = createMuiTheme({
    //     direction: settings.direction,
    //     responsiveFontSizes: settings.responsiveFontSizes,
    //     theme: settings.theme,
    // })
    
    const handleTheme = () => {        
        // saveSettings({theme:theme ==='Dark' ? 'S2' : 'ONE_DARK'})
        settings.theme = theme === 'Dark' ? 'S2' : 'ONE_DARK'
        setTheme(theme === 'Light' ? 'Dark' : 'Light')
        localStorage.setItem('settings', JSON.stringify(settings));
        saveSettings({theme:JSON.parse(localStorage.getItem('settings')).theme?JSON.parse(localStorage.getItem('settings')).theme: settings.theme})
        
        theme === 'Dark' ? document.body.classList.add(
            'theme-light') :
            document.body.classList.remove(
                'theme-light')
    }
   
   useEffect(() => {
    theme === 'Light' &&  document.body.classList.add(
        'theme-light'
      ) 
      return () => {
        document.body.classList.remove('theme-light')
      }
   }, [theme])

    return (
        <>
            {/* <ThemeProvider theme={muiTheme}> */}
                <Box
                    display="flex"
                    alignItems="center"
                    component={ButtonBase}
                    onClick={handleOpen}
                    ref={ref}
                >
                    <Avatar 
                    className={classes.avatar} title={`${userDetails.firstName} ${userDetails.lastName}`}>
                        {Object.keys(userDetails).length
                            ? userDetails?.firstName[0] +
                              userDetails?.lastName[0]
                            : 'UV'}
                    </Avatar>
                    <Hidden smDown>
                        <Typography variant="h6" color="inherit">
                            {user?.name || ''}
                        </Typography>
                    </Hidden>
                </Box>
                <Menu
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    PaperProps={{ className: classes.popover }}
                    getContentAnchorEl={null}
                    anchorEl={ref.current}
                    open={isOpen}
                >
                    <MenuItem
                        onClick={handleClose}
                        component={RouterLink}
                        to="/profile"
                    >
                        Profile
                    </MenuItem>
                    <MenuItem>
                        <span>{theme} Theme</span>{' '}
                        <Switch
                            checked={theme==="Dark"}
                            onClick={handleTheme}
                        />
                    </MenuItem>
                    {canAccessAcount(userDetails) && (
                        <MenuItem
                            onClick={handleClose}
                            component={RouterLink}
                            to="/account"
                        >
                            Account
                        </MenuItem>
                    )}
                    {canSwitchOrganization(userDetails) && (
                        <MenuItem
                            onClick={() => {
                                setShowSwitchOrganization(true)
                                handleClose()
                            }}
                        >
                            Switch Organization
                        </MenuItem>
                    )}

                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
                <Modal
                    open={showSwitchOrganization}
                    onClose={() => {
                        setShowSwitchOrganization(false)
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className={classes.switchOrgModal}
                >
                    <>
                    <SwitchOrganization
                        className={classes.switchOrg}
                        setShowSwitchOrganization={setShowSwitchOrganization}
                    ></SwitchOrganization>
                    </>
                </Modal>
            {/* </ThemeProvider> */}
        </>
    )
}

export default Account
