export const getColumnFilters = (tableState) => {
    const filters = {}
    for (const [key, value] of Object.entries(tableState.columns)) {
        const filterColumn = value.name
        const filterValues = tableState.filterList[key]
        if (filterValues.length > 0) {
            if (filterColumn === 'status') {
                filterValues.forEach(v => {
                    filters[v.toLowerCase()] = true
                })

            } else {
                filters[filterColumn] = filterValues

            }
        }
    }
    return filters
}

export const renameKeys = (obj, renameMap) => {
    const newObj = {}
    for (const [key, value] of Object.entries(obj)) {
        if (renameMap[key]) {
            newObj[renameMap[key]] = value
        } else {
            if (key === 'status') {
                value.forEach(v => {
                    newObj[v.toLowerCase()] = true
                });
            } else {
                newObj[key] = value
            }
        }
    }
    return newObj
}

export const getSortValue = (sortColumn, sortDirection) => {
    if (sortDirection === 'desc') {
        return `-${sortColumn}`
    }
    return sortColumn
}

export const urlFilter = (initialFilters) => {
    const filters = {}
    const statusArr = []
    for (const [key] of Object.entries(initialFilters)) {
        const filterColumn = key
        const filterValues = initialFilters[key]
        if (filterValues.length > 0) {
            if ((key === 'closed') || (key === 'investigating')) {
                statusArr.push(key.toUpperCase())
                filters.status = statusArr
                delete initialFilters.key
                
            } else {
                
                filters[filterColumn] = filterValues
            }
            
        }
    }
    return filters
}