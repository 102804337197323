import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Paper,
    Typography,
    makeStyles,
} from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import CreateUser from './CreateUser'
import { roles } from 'src/context/UserContext'

const roleMap = {
    [roles.ADMIN]: 'System Administrator',
    [roles.PORTAL_ADMIN]: 'Portal Administrator',
    [roles.PORTAL_USER]: 'Portal User',
    [roles.PENTEST_ADMIN]: 'Pentest Administrator',
    [roles.PENTESTER]: 'Pentest User',
    [roles.ETL_SERVICE]: 'Service Account',
}

const useStyles = makeStyles((theme) => ({
    root: {        
        position:'relative',
        top: '-8px'
    },
    accountIcon: {
        height: '60px',
        width: '60px',
    },
    adminAccountMetaContainer: {
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'space-between',
    },
    adminAccountMeta: {
        marginTop: '16px',
    },
    cardRoot: {
        width: '22%',        
        margin: `0 ${theme.spacing(3)}px ${theme.spacing(6)}px`,
        border: `2px solid ${theme.palette.background.lightBlue}`,
        position: 'relative',
        paddingBottom: '58px',
        
          "@media (min-width: 1500px) and (max-width: 1900px)": {
            width: '23%',
            margin: "0 12px 24px"
          }, 
          "@media (min-width: 779px) and (max-width: 1499px)": {
            width: '30%',
            margin: "0 12px 24px"
          },
          "@media (max-width: 778px)": {
            width: '45%',
            margin: "0 6px 12px"
          },         

        "&:hover": {
            boxShadow: "0 5px 10px 0px rgba(0,0,0,0.7)"
          },
    },
    cardHeading: {
        paddingTop: theme.spacing(3),
        margin: `0 auto ${theme.spacing(2)}px`,
        display: 'flex',
        justifyContent: 'space-between',
        width: '90%',
    },
    createUserButton: {
        margin: `0 ${theme.spacing(3)}px`,
        justifySelf: 'flex-end',
        position:'absolute',
        right: "10px",
        top: '-46px'
    },
    userContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: "-24px",
        maxHeight: 'calc(100vh - 160px)',
        overflowY: 'auto'
    },
    userButtonContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', 
        width: "100%",       
        position: 'absolute',
        bottom: '0',
        borderTop: `1px solid ${theme.palette.action.focus}`
    },
    userButton: {
        display: 'block',
        width: 'fit-content',
        margin: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
}))

const DeleteConfirmation = ({ open, onClose, onConfirmation, userName }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Are you sure you want to delete {userName}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {userName} will be permanently deleted from your account.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button
                    onClick={async () => {
                        await onConfirmation().then(() => onClose())
                    }}
                    color="primary"
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const IndividualUser = ({
    id,
    firstName,
    lastName,
    role,
    emailAddress,
    onUserDelete,
    visibleAdminOptions,
}) => {
    const classes = useStyles()
    const [userDeletePrompt, setDeletePrompt] = useState(false)
    const roleAllowedReset = role !== 0

    return (
        
        <Paper className={classes.cardRoot}>
            <DeleteConfirmation
                open={userDeletePrompt}
                onClose={setDeletePrompt.bind(this, false)}
                onConfirmation={onUserDelete.bind(this, id)}
                userName={`${firstName} ${lastName}`}
            />
            <Box className={classes.cardHeading}>
                <AccountCircleIcon className={classes.accountIcon} />
                <Box
                    style={{
                        alignSelf: 'center',
                        width: '74%',
                        wordBreak: 'break-all',
                    }}
                >
                    <Typography variant="h3">
                        {`${firstName} ${lastName}`}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ alignSelf: 'center' }}
                    >
                        {emailAddress}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        style={{ alignSelf: 'center' }}
                    >
                        {roleMap[role.role]}
                    </Typography>
                </Box>
            </Box>
            {visibleAdminOptions && (
                <>
                    {/* <Divider></Divider> */}
                    <Box className={classes.userButtonContainer}>
                        {/* <Button
                            color="primary"
                            variant="contained"
                            className={classes.userButton}
                            // disabled={!roleAllowedReset}
                            disabled
                        >
                            Reset Password
                        </Button> */}
                        <Button
                            color="secondary"
                            variant="contained"
                            size="small"
                            className={classes.userButton}
                            disabled={!roleAllowedReset}
                            onClick={async (e) => {
                                // await onUserDelete(e.target.value)
                                setDeletePrompt(true)
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                </>
            )}
        </Paper>
    )
}

const isAdminUser = (role) => {
    return ['PORTAL_ADMIN', 'ADMIN', 'PENTEST_ADMIN'].includes(role)
}

const AdminAccount = ({
    userAccounts,
    onUserDelete,
    onUserCreate,
    currentRole,
}) => {
    const classes = useStyles()

    const [createUserModalDisplayed, setCreateUserModal] = useState(false)
    const isAdmin = isAdminUser(currentRole?.role)

    return (
        <Box className={classes.root}>
            {isAdmin && (
                <Box display="flex">
                    <Button
                        color="primary"
                        onClick={() => {
                            setCreateUserModal(!createUserModalDisplayed)
                        }}
                        className={classes.createUserButton}
                        variant="contained"
                        size="small"
                    >
                        Create User
                    </Button>
                </Box>
            )}
            <CreateUser
                active={createUserModalDisplayed}
                onClose={setCreateUserModal.bind(this, false)}
                onUserCreate={onUserCreate}
            />
            <Box className={classes.userContainer}>
                {userAccounts.map((userAccount, index) => (
                    <IndividualUser
                        key={index}
                        {...userAccount}
                        onUserDelete={onUserDelete}
                        visibleAdminOptions={isAdmin}
                    />
                ))}
            </Box>
        </Box>
    )
}

AdminAccount.defaultProps = {
    userAccounts: [],
    onUserDelete: () => {},
}

AdminAccount.propTypes = {
    userAccounts: PropTypes.array,
    onUserDelete: PropTypes.func,
    currentRole: PropTypes.object,
}

export default AdminAccount
